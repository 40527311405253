exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-2022-in-review-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/2022-in-review.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-2022-in-review-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-concept-of-finality-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/concept-of-finality.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-concept-of-finality-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-data-in-the-age-of-exponential-growth-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/data-in-the-age-of-exponential-growth.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-data-in-the-age-of-exponential-growth-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-decentralised-social-media-the-future-beyond-traditional-networks-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/decentralised-social-media-the-future-beyond-traditional-networks.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-decentralised-social-media-the-future-beyond-traditional-networks-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-driving-digital-sustainability-the-impact-of-small-changes-on-the-web-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/driving-digital-sustainability-the-impact-of-small-changes-on-the-web.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-driving-digital-sustainability-the-impact-of-small-changes-on-the-web-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-future-development-trends-2023-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/future-development-trends-2023.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-future-development-trends-2023-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-future-digital-trends-2023-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/future-digital-trends-2023.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-future-digital-trends-2023-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-i-dont-know-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/i-dont-know.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-i-dont-know-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-content-posts-its-time-for-a-new-approach-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/src/content/posts/its-time-for-a-new-approach.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-content-posts-its-time-for-a-new-approach-mdx" */)
}

